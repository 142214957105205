var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "권한",
                  tableId: "table",
                  columnSetting: false,
                  usePaging: false,
                  isFullScreen: false,
                  columns: _vm.grid.columns,
                  isExcelDown: false,
                  data: _vm.grid.data,
                  filtering: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getGrpList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c(
              "c-table",
              {
                ref: "tablemenu",
                attrs: {
                  title: "권한별 메뉴목록",
                  tableId: "tablemenu",
                  columnSetting: false,
                  usePaging: false,
                  isFullScreen: false,
                  isExcelDown: false,
                  columns: _vm.gridmenu.columns,
                  data: _vm.gridmenu.data,
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.isSelected && _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "메뉴추가/삭제", icon: "add" },
                              on: { btnClicked: _vm.addMenu },
                            })
                          : _vm._e(),
                        _vm.isSelected && _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "권한저장", icon: "save" },
                              on: { btnClicked: _vm.saveMenu },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          ref: "menuDialog",
          attrs: { persistent: "" },
          model: {
            value: _vm.isMenuOpen,
            callback: function ($$v) {
              _vm.isMenuOpen = $$v
            },
            expression: "isMenuOpen",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "menu-card",
              staticStyle: { width: "600px", height: "800px" },
            },
            [
              _c(
                "q-card-section",
                {
                  staticClass:
                    "row items-center q-pb-none px-2 py-1 bg-orange-custom text-white",
                },
                [
                  _c("div", { staticClass: "text-h6" }, [_vm._v("메뉴")]),
                  _c("q-space"),
                  _c(
                    "q-btn",
                    {
                      attrs: { icon: "done", flat: "", round: "", dense: "" },
                      on: { click: _vm.selectMenu },
                    },
                    [_c("q-tooltip", [_vm._v(" 메뉴추가 및 삭제 ")])],
                    1
                  ),
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { icon: "close", flat: "", round: "", dense: "" },
                  }),
                ],
                1
              ),
              _c("q-separator"),
              _c(
                "q-card-section",
                { staticClass: "menu-content p-2" },
                [
                  _c("q-tree", {
                    ref: "menuTree",
                    attrs: {
                      nodes: _vm.menuTree,
                      "node-key": "sysMenuId",
                      "label-key": "menuNm",
                      "children-key": "children",
                      "default-expand-all": false,
                      "selected-color": "primary",
                      "tick-strategy": "leaf",
                      ticked: _vm.ticked,
                      editable: _vm.editable,
                    },
                    on: {
                      "update:ticked": function ($event) {
                        _vm.ticked = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }